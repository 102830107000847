.container {
  display: grid;
  max-width: 800px;

  margin: 40px auto;
  grid-template-columns: 1fr 2fr 1fr;
}
.sidebar {
  text-align: center;
}
.sidebar ul {
  border-right: 4px solid #114949;
}
.sidebar h3 {
  padding: 6px;

  background: var(--secondary);
  border: 4px solid #114949;
  border-bottom: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: floralwhite;
  margin-left: 90px;
}
.sidebar li {
  border: 2px solid #f2f2f2;
  border-right: none;
  box-shadow: 3px 3px 5px rgba(50, 50, 50, 0.1);
  padding: 8px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-left: 20px;
}
.sidebar li:hover {
  background: var(--secondary);
  border: 2px solid #114949;
  border-right: none;
  color: floralwhite;
  cursor: pointer;
  margin-left: 2px;
}
.content .title {
  text-align: center;
}

/*BudgetDetails: */
.budget-box {
  margin-left: 40px;
  position: relative;
  padding-bottom: 50px;
}
.budget-box button {
  position: absolute;
  bottom: 0;
  right: 0;
}
.budget {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
}
.budget h3 {
  margin: auto;
  background: var(--secondary);
  border: 2px solid #114949;
  color: floralwhite;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: none;
  text-align: center;
  width: 400px;
  font-weight: 400;
}
.budget label {
  font-size: 1.2em;
  font-weight: 500;
  padding-bottom: 4px;
}
.res {
  margin-left: 10px;
  padding-bottom: 8px;
}

.budget input {
  margin-left: 10px;
  margin-bottom: 8px;
  padding: 6px;
  max-width: 100px;
  text-align: end;
  font-size: 1em;
}
.expense-trans {
  background: var(--honey-bee);
  border-left: 2px solid rgb(136, 7, 7);
  border-radius: 0.08rem;
  padding: 0.6rem 1rem;
}
.income-trans {
  background: var(--honey-bee);
  border-left: 2px solid #32692b;
  border-radius: 0.08rem;
  padding: 0rem 1rem 0rem 1rem;
}

.right {
  padding-left: 30px;
}
.right form {
  padding: 20px;

  background: var(--secondary);
  border-radius: 10px;
}
.right input,
.right select {
  display: block;
  box-sizing: border-box;
  width: 100%;

  margin-top: 8px;
  padding: 10px;

  border: 0;
  border-radius: 4px;

  color: #555;
  font-size: 1em;
}
.right label {
  margin: 0 auto 20px;
  display: block;
  color: #fff;
}
.right button {
  display: block;
  width: 100%;

  padding: 6px 12px;
  background: transparent;

  border: 2px solid #fff;
  border-radius: 4px;

  font-size: 1em;
  color: #fff;

  cursor: pointer;
}
.right h3 {
  margin-bottom: 20px;
  color: var(--secondary);
}
.right aside {
  margin-top: 10px;
  color: #555;
}
.right aside li {
  margin: 10px;
}
.right .type-label {
  margin-bottom: 0px;
}
