.navbar {
  box-sizing: border-box;
  background: #f0b942;

  display: flex;
  justify-content: center;

  margin: auto;
}
.navbar ul {
  width: 100%;
  max-width: 900px;

  display: flex;
  justify-content: space-around;
  align-items: center;
}
.navbar li {
  padding: 10px 20px;
}
.title {
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 1.6em;
}

.navbar a {
  color: #333;
  text-decoration: none;
}
.tab-label {
  display: flex;
  justify-content: center;
}
.budget-tab:hover {
  background: #114949;
  color: floralwhite;
}
.budget-tab:hover .tab-label {
  color: floralwhite;
}
