.login-form {
  max-width: 360px;
  margin: 60px auto;
  padding: 20px;
}
.login-form label {
  display: block;
  margin: 30px auto;
}
.login-form span {
  display: block;
  margin-bottom: 6px;
}
.login-form input {
  padding: 8px 6px;
  font-size: 1em;
  color: #777;
  width: 100%;
}
.unverified {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.resend-btn {
  padding: 8px 60px;
  margin-top: 20px;
  width: 50%;

  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 8px rgba(48, 33, 33, 0.466);

  background: var(--secondary);
  color: black;

  font-family: "Inknut Antiqua", serif;
  font-weight: 600;
  font-size: 0.8em;

  cursor: pointer;
}
.resend-btn:hover {
  background: var(--primary);
  color: black;
}
