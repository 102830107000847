.content {
  padding-right: 30px;
}
.sidebar {
  padding-left: 30px;
}
.sidebar form {
  padding: 20px;

  background: var(--secondary);
  border-radius: 10px;
}
.sidebar input,
.sidebar select {
  display: block;
  box-sizing: border-box;
  width: 100%;

  margin-top: 8px;
  padding: 10px;

  border: 0;
  border-radius: 4px;

  color: #555;
  font-size: 1em;
}
.sidebar label {
  margin: 0 auto 20px;
  display: block;
  color: #fff;
}
.sidebar button {
  display: block;
  width: 100%;

  padding: 6px 12px;
  background: transparent;

  border: 2px solid #fff;
  border-radius: 4px;

  font-size: 1em;
  color: #fff;

  cursor: pointer;
}
.sidebar h3 {
  margin-bottom: 20px;
  color: var(--secondary);
}
.sidebar aside {
  margin-top: 10px;
  color: #555;
}
.sidebar aside li {
  margin: 10px;
}
.sidebar .type-label {
  margin-bottom: 0px;
}
.type {
  display: flex;
  align-items: center;
}
.type input,
.type label {
  margin: 6px 4px;
}
.add-btn {
  margin-top: 10px;
}

.transactions li {
  margin: 30px auto;
  border: 1px solid #f2f2f2;
  box-shadow: 3px 3px 5px rgba(50, 50, 50, 0.1);
  padding: 20px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-left: 4px solid var(--secondary);
}

.transactions .expense {
  border-left: 4px solid salmon;
}
.transactions .income {
  border-left: 4px solid var(--secondary);
}

.transactions .name {
  color: #777;
  font-size: 1.4em;
}
.transactions .amount {
  margin-left: auto;
  margin-right: 40px;
  color: #777;
  font-weight: bold;
  font-size: 1.6em;
}
.transactions button {
  position: absolute;
  top: 0;
  right: 0;
  background: #ddd;
  color: #777;
  border: none;
  padding: 12px 8px;
  text-align: center;
  line-height: 0;
  font-size: 0.9em;
  cursor: pointer;
}
